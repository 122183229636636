.not-fw .image-banner > .row {
  margin-right: 0;
  margin-left: 0; }

.image-banner .image-heading-container {
  padding: 0;
  position: static; }
  .image-banner .image-heading-container .heading-btns {
    display: block;
    margin-top: 0.75rem; }
    .image-banner .image-heading-container .heading-btns .cta-btn {
      margin: 0.75rem 0;
      vertical-align: top;
      white-space: nowrap; }
    .image-banner .image-heading-container .heading-btns.twoButtons .cta-btn:first-child {
      margin-right: 1rem; }
  .image-banner .image-heading-container .heading-inner {
    text-align: left; }
    .image-banner .image-heading-container .heading-inner.left {
      text-align: left; }
    .image-banner .image-heading-container .heading-inner.right {
      text-align: right; }
    .image-banner .image-heading-container .heading-inner.center {
      text-align: center; }
  .image-banner .image-heading-container.dark .image-heading-text {
    color: #000; }
    .image-banner .image-heading-container.dark .image-heading-text P {
      color: #000; }
  .image-banner .image-heading-container.light .image-heading-text {
    color: #fff; }
    .image-banner .image-heading-container.light .image-heading-text P {
      color: #fff; }
  .image-banner .image-heading-container.text-below {
    padding-top: rem(16); }
    .image-banner .image-heading-container.text-below .heading-inner {
      display: inline-block; }
    .image-banner .image-heading-container.text-below.left .image-heading-text {
      text-align: left; }
    .image-banner .image-heading-container.text-below.right .image-heading-text {
      text-align: right; }
    .image-banner .image-heading-container.text-below.center .image-heading-text {
      text-align: center; }
  .image-banner .image-heading-container.text-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
    .image-banner .image-heading-container.text-overlay > .container {
      position: relative;
      height: 100%;
      width: 100%; }
    .image-banner .image-heading-container.text-overlay .image-heading-text {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      text-align: left;
      margin: 0;
      max-width: 100%; }
      @media (min-width: 544px) {
        .image-banner .image-heading-container.text-overlay .image-heading-text {
          width: auto; } }
    .image-banner .image-heading-container.text-overlay .heading-btns .cta-btn {
      margin-bottom: 0; }
    .image-banner .image-heading-container.text-overlay.top .image-heading-text {
      top: 1rem;
      left: 50%;
      transform: translateX(-50%);
      text-align: center; }
    .image-banner .image-heading-container.text-overlay.right .image-heading-text {
      right: 0;
      transform: translateY(-50%);
      text-align: right; }
    .image-banner .image-heading-container.text-overlay.bottom .image-heading-text {
      top: unset;
      bottom: 5vh;
      left: 50%;
      transform: translateX(-50%);
      text-align: center; }
    .image-banner .image-heading-container.text-overlay.left .image-heading-text {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      text-align: left; }
    .image-banner .image-heading-container.text-overlay.center .image-heading-text {
      top: 50%;
      left: 50%;
      text-align: center;
      transform: translate(-50%, -50%); }
    .image-banner .image-heading-container.text-overlay.top.right .image-heading-text {
      top: 5vh;
      right: 0;
      left: unset;
      transform: unset;
      text-align: right; }
    .image-banner .image-heading-container.text-overlay.top.left .image-heading-text {
      top: 5vh;
      left: 0;
      transform: unset;
      text-align: left; }
    .image-banner .image-heading-container.text-overlay.bottom.right .image-heading-text {
      top: unset;
      bottom: 5vh;
      right: 0;
      left: unset;
      transform: unset;
      text-align: right; }
    .image-banner .image-heading-container.text-overlay.bottom.left .image-heading-text {
      top: unset;
      bottom: 5vh;
      right: unset;
      left: 0;
      transform: unset;
      text-align: left; }
    .image-banner .image-heading-container.text-overlay.left.center .image-heading-text {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      text-align: left; }
      @media (min-width: 544px) {
        .image-banner .image-heading-container.text-overlay.left.center .image-heading-text {
          left: 25%;
          transform: translate(-50%, -50%); } }
    .image-banner .image-heading-container.text-overlay.right.center .image-heading-text {
      top: 50%;
      left: unset;
      right: 0;
      transform: translateY(-50%);
      text-align: right; }
      @media (min-width: 544px) {
        .image-banner .image-heading-container.text-overlay.right.center .image-heading-text {
          right: 25%;
          transform: translate(50%, -50%);
          text-align: left; } }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.image-banner .mainbanner-figure {
  width: 100%;
  overflow: hidden; }
  .image-banner .mainbanner-figure .mainbanner-image.lazyloaded {
    height: auto;
    width: 100%; }
  .image-banner .mainbanner-figure .vid-poster.lazyloaded {
    width: 100%; }
  @media (min-width: 769px) {
    .image-banner .mainbanner-figure .desk-dark {
      color: black; }
    .image-banner .mainbanner-figure .desk-light {
      color: white; } }
  @media (max-width: 768.98px) {
    .image-banner .mainbanner-figure .mobile-dark {
      color: black; }
    .image-banner .mainbanner-figure .mobile-light {
      color: white; } }

.image-banner .image-heading-container {
  padding: 0 15px;
  z-index: 3; }
  .image-banner .image-heading-container .heading-inner .eyebrow {
    margin-bottom: 1.25rem; }
    @media (min-width: 769px) {
      .image-banner .image-heading-container .heading-inner .eyebrow {
        margin-bottom: 1.25rem; } }
  .image-banner .image-heading-container .heading-inner .headline {
    display: block;
    margin-bottom: 0; }
    @media (min-width: 769px) {
      .image-banner .image-heading-container .heading-inner .headline {
        margin-bottom: 0; } }
  .image-banner .image-heading-container .heading-inner .description {
    display: block; }
  .image-banner .image-heading-container .heading-btns {
    display: block;
    margin-top: 1rem; }
    @media (min-width: 769px) {
      .image-banner .image-heading-container .heading-btns {
        display: flex;
        margin-top: 1.625rem; } }
    .image-banner .image-heading-container .heading-btns .btn {
      width: auto;
      min-width: 10.125rem; }
  .image-banner .image-heading-container.text-below {
    padding-top: 1rem; }
    .image-banner .image-heading-container.text-below .image-heading-text {
      padding: 1.5rem 0; }
      @media (max-width: 768.98px) {
        .image-banner .image-heading-container.text-below .image-heading-text {
          padding: 1.25rem 0; } }
    .image-banner .image-heading-container.text-below .heading-inner {
      display: inline-block; }
    .image-banner .image-heading-container.text-below.left .image-heading-text {
      text-align: left; }
    .image-banner .image-heading-container.text-below.right .image-heading-text {
      text-align: right; }
    .image-banner .image-heading-container.text-below.center .image-heading-text {
      text-align: center; }
  .image-banner .image-heading-container.overlay-mobile-only .image-heading-text {
    padding: 2.25rem 0; }
    @media (min-width: 992px) {
      .image-banner .image-heading-container.overlay-mobile-only .image-heading-text {
        padding: 2.5rem 0; } }
  @media (max-width: 768.98px) {
    .image-banner .image-heading-container.overlay-mobile-only {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0; }
      .image-banner .image-heading-container.overlay-mobile-only .heading-inner {
        display: inline-block; }
      .image-banner .image-heading-container.overlay-mobile-only .image-heading-text {
        position: absolute;
        padding: 1.875rem 1.875rem; }
      .image-banner .image-heading-container.overlay-mobile-only.left .image-heading-text {
        text-align: left; }
      .image-banner .image-heading-container.overlay-mobile-only.right .image-heading-text {
        text-align: right; }
      .image-banner .image-heading-container.overlay-mobile-only.center .image-heading-text {
        text-align: center; }
      .image-banner .image-heading-container.overlay-mobile-only.mobile-top .image-heading-text {
        top: 0;
        bottom: unset;
        left: 0;
        right: unset;
        transform: unset; }
      .image-banner .image-heading-container.overlay-mobile-only.mobile-bottom .image-heading-text {
        top: unset;
        bottom: 0;
        left: 0;
        right: unset;
        transform: unset; }
      .image-banner .image-heading-container.overlay-mobile-only.mobile-center .image-heading-text {
        top: 50%;
        transform: translateY(-50%);
        bottom: unset;
        left: 0;
        right: unset; } }
  @media (min-width: 769px) {
    .image-banner .image-heading-container.overlay-desktop-only {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; } }
  @media (min-width: 992px) {
    .image-banner .image-heading-container.overlay-desktop-only .image-heading-text {
      top: 50%;
      transform: translateY(-50%);
      padding: 5rem 2.375rem; } }
  @media (min-width: 769px) {
    .image-banner .image-heading-container.overlay-desktop-only .image-heading-text {
      position: absolute;
      text-align: left;
      margin: 0;
      max-width: 100%;
      padding: 2.25rem 2.375rem; } }
  @media (min-width: 544px) {
    .image-banner .image-heading-container.overlay-desktop-only .image-heading-text {
      width: auto; } }
  @media (max-width: 991.98px) {
    .image-banner .image-heading-container.overlay-desktop-only .image-heading-text {
      padding: 1.875rem 1.875rem; } }
  @media (max-width: 768.98px) {
    .image-banner .image-heading-container.overlay-desktop-only .image-heading-text {
      padding: 1.25rem 1.25rem; } }
  @media (max-width: 991.98px) {
    .image-banner .image-heading-container.overlay-desktop-only {
      padding-top: 0; }
      .image-banner .image-heading-container.overlay-desktop-only .image-heading-text {
        transform: none; }
      .image-banner .image-heading-container.overlay-desktop-only .heading-inner {
        display: inline-block; }
      .image-banner .image-heading-container.overlay-desktop-only.left .image-heading-text {
        text-align: left; }
      .image-banner .image-heading-container.overlay-desktop-only.right .image-heading-text {
        text-align: right; } }
    @media (max-width: 991.98px) and (min-width: 769px) {
      .image-banner .image-heading-container.overlay-desktop-only.right .image-heading-text {
        padding: 5rem 1.55556% 5rem 0; } }
  @media (max-width: 991.98px) {
      .image-banner .image-heading-container.overlay-desktop-only.center .image-heading-text {
        text-align: center; } }
  @media (max-width: 768.98px) {
    .image-banner .image-heading-container.overlay-desktop-only .image-heading-text {
      position: static; } }
  .image-banner .image-heading-container.overlay-desktop-only > .container {
    position: relative;
    height: 100%;
    width: 100%; }
  .image-banner .image-heading-container.overlay-desktop-only .heading-btns .cta-btn {
    margin-bottom: 0; }
  .image-banner .image-heading-container.overlay-desktop-only.top .image-heading-text {
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    text-align: center; }
  .image-banner .image-heading-container.overlay-desktop-only.right .image-heading-text {
    right: 0;
    text-align: right; }
    @media (min-width: 992px) {
      .image-banner .image-heading-container.overlay-desktop-only.right .image-heading-text {
        transform: translateY(-50%); } }
  .image-banner .image-heading-container.overlay-desktop-only.bottom .image-heading-text {
    top: unset;
    bottom: 5vh;
    left: 50%;
    transform: translateX(-50%);
    text-align: center; }
  .image-banner .image-heading-container.overlay-desktop-only.left .image-heading-text {
    left: 0;
    text-align: left; }
    @media (min-width: 992px) {
      .image-banner .image-heading-container.overlay-desktop-only.left .image-heading-text {
        top: 50%;
        transform: translateY(-50%); } }
  .image-banner .image-heading-container.overlay-desktop-only.center .image-heading-text {
    text-align: center; }
    @media (min-width: 992px) {
      .image-banner .image-heading-container.overlay-desktop-only.center .image-heading-text {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); } }
  .image-banner .image-heading-container.overlay-desktop-only.top.right .image-heading-text {
    top: 5vh;
    right: 0;
    left: unset;
    transform: unset;
    text-align: right; }
  .image-banner .image-heading-container.overlay-desktop-only.top.left .image-heading-text {
    top: 5vh;
    left: 0;
    transform: unset;
    text-align: left; }
  .image-banner .image-heading-container.overlay-desktop-only.bottom.right .image-heading-text {
    top: unset;
    bottom: 5vh;
    right: 0;
    left: unset;
    transform: unset;
    text-align: right; }
  .image-banner .image-heading-container.overlay-desktop-only.bottom.left .image-heading-text {
    top: unset;
    bottom: 5vh;
    right: unset;
    left: 0;
    transform: unset;
    text-align: left; }
  .image-banner .image-heading-container.overlay-desktop-only.left.center .image-heading-text {
    left: 0;
    text-align: left; }
    @media (min-width: 992px) {
      .image-banner .image-heading-container.overlay-desktop-only.left.center .image-heading-text {
        top: 50%;
        transform: translateY(-50%); } }
    @media (max-width: 991.98px) {
      .image-banner .image-heading-container.overlay-desktop-only.left.center .image-heading-text {
        left: 3.5%;
        transform: none; } }
  .image-banner .image-heading-container.overlay-desktop-only.right.center .image-heading-text {
    left: unset;
    right: 0;
    text-align: right; }
    @media (min-width: 992px) {
      .image-banner .image-heading-container.overlay-desktop-only.right.center .image-heading-text {
        top: 50%;
        transform: translateY(-50%); } }
  .image-banner .image-heading-container.overlay-desktop-only .heading-inner {
    padding: 0; }
  .image-banner .image-heading-container.overlay-desktop-only.top .image-heading-text {
    top: 0; }
  .image-banner .image-heading-container.overlay-desktop-only.bottom .image-heading-text {
    bottom: 0; }
  .image-banner .image-heading-container.overlay-desktop-only.top.right .image-heading-text {
    top: 0; }
  .image-banner .image-heading-container.overlay-desktop-only.top.left .image-heading-text {
    top: 0; }
  .image-banner .image-heading-container.overlay-desktop-only.bottom.right .image-heading-text {
    bottom: 0; }
  .image-banner .image-heading-container.overlay-desktop-only.bottom.left .image-heading-text {
    bottom: 0; }
  .image-banner .image-heading-container.text-overlay .image-heading-text {
    padding: 2.25rem 1.875rem; }
    @media (min-width: 992px) {
      .image-banner .image-heading-container.text-overlay .image-heading-text {
        padding: 5rem 2.375rem; } }
    @media (max-width: 768.98px) {
      .image-banner .image-heading-container.text-overlay .image-heading-text {
        padding: 1.875rem; } }
  .image-banner .image-heading-container.text-overlay .heading-inner {
    padding: 0; }
  .image-banner .image-heading-container.text-overlay.top .image-heading-text {
    top: 0; }
  .image-banner .image-heading-container.text-overlay.bottom .image-heading-text {
    bottom: 0; }
  .image-banner .image-heading-container.text-overlay.top.right .image-heading-text {
    top: 0; }
  .image-banner .image-heading-container.text-overlay.top.left .image-heading-text {
    top: 0; }
  .image-banner .image-heading-container.text-overlay.bottom.right .image-heading-text {
    bottom: 0; }
  .image-banner .image-heading-container.text-overlay.bottom.left .image-heading-text {
    bottom: 0; }
  @media (max-width: 768.98px) {
    .image-banner .image-heading-container.text-overlay.mobile-top .image-heading-text {
      top: 0;
      bottom: unset;
      left: 0;
      right: unset;
      transform: unset; }
    .image-banner .image-heading-container.text-overlay.mobile-bottom .image-heading-text {
      top: unset;
      bottom: 0;
      left: 0;
      right: unset;
      transform: unset; }
    .image-banner .image-heading-container.text-overlay.mobile-center .image-heading-text {
      bottom: unset;
      left: 0;
      right: unset; } }

@media (min-width: 544px) {
  .carousel .image-banner .image-heading-container.overlay-desktop-only.right.center .image-heading-text {
    top: 57%;
    right: 26.5%;
    transform: translate(50%, -50%);
    text-align: left;
    padding: 0 1.55556% 5rem 0; } }

.video-bg {
  overflow: hidden;
  width: 100%;
  aspect-ratio: 16/8;
  pointer-events: none;
  z-index: 1; }
  .video-bg iframe {
    width: 300%;
    height: 100%;
    margin-left: -100%; }

.has-vid-bg .img-bg {
  padding: 0 15px;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease;
  z-index: 2; }

.js-has-video.js-playing .img-bg {
  opacity: 0; }

.js-has-video.js-paused .img-bg {
  opacity: 1; }
